<template>
  <div></div>
</template>

<script>
export default {
  name: 'Login',
  mounted() {
    const code = this.$route.query.code
    const state = this.$route.query.state
    let appType = 2
    if (state && state === 'jssdk') appType = 3
    this.$store.state.sdk?.changeSdkType(appType)
    if (code && typeof code === 'string') {
      console.log(this.$store.state.sdk)
      this.$store.state.sdk?.getToken({ code }).then((res) => {
        if (res && res.access_token) {
          this.$store.commit('SETTOKEN', res)
          this.$store.dispatch('initSdk')
          this.$router.replace('/')
        }
      })
    }
  }
}
</script>